<template>
    <div class="app-container">
        <ToolBar :dicts="dicts" @on-change="dealWithToolBarChange"></ToolBar>
        <CustomTable :dicts="dicts" :queryParams="queryParams"></CustomTable>
    </div>
</template>

<script>
    import ToolBar from "./ToolBar";
    import CustomTable from "./CustomTable";
    import { mapGetters } from 'vuex'
    import {GET_DICTS} from '@/store/constants';

    export default {
        name: "terminalActivityQuery",
        components: {CustomTable, ToolBar},
        computed: {
            ...mapGetters({
                dicts: GET_DICTS
            })
        },
        data(){
            return{
                queryParams: {},
            }
        },
        methods: {
            dealWithToolBarChange(params) {
                this.queryParams = params;
            },
        }
    }
</script>

<style scoped>

</style>
